<template>
    <div class="background">
        <div class="contain" @click="unfocusSelection">
            <div class="header">
                <div class="topbar">
                    <div style="flex-shrink: 1; background: transparent">
                        <img class="icon-stack" src="/images/back.png" width="30px" @click="$router.back()" />
                    </div>
                    <div class="right-bar">
                        <img src="/images/holy_point.png" class="hw-logo" />
                        <div class="point-wrapper">
                            <div class="point align-self-center font-weight-bold">
                                {{ currentPoint }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="initStatus == 0" class="text-center mt-4">
                <b-spinner variant="light" />
            </div>
            <div v-else-if="initStatus == -1" class="text-center mt-4"> 
                <div class="text-light mb-2">Failed to load chest packages.</div>
                <b-button size="sm" variant="primary" @click="init">Retry</b-button>
            </div>
            <div v-else class="content" style="margin-bottom: 100px">
                <!-- <img :src="$BASE_IMAGE_PATH + image" class="banner-image" /> -->
                <h3 class="mb-0">{{ name }}</h3>
                <div v-if="daysLeft <= 15" class="small">
                    Ending {{ daysLeft <= 0 ? "soon!" : "in " + daysLeft + " day(s)" }}
                </div>
                <hr style="border-color: white" class="mt-2 mb-1" />
                <div>Rewards</div>
                <hr style="border-color: white" class="my-1" />
                <div class="reward-container mt-3" style="margin-botton: 150px">
                    <div v-for="reward in rewards" v-bind:key="reward.name" style="width: 100px" :class="'mb-3 bg-reward bg-' + reward.css">
                        <div :class="'bg-inner-reward bg-inner-' + reward.css">
                            <img :src="reward.image" style="width: 80px">
                            <div class="mt-2 reward-ellipsis">
                                <span class="inner-span">{{ reward.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <nav v-if="initStatus == 1" class="menu">
            <input type="checkbox" href="#" class="menu-open" name="menu-open" id="menu-open" v-model="isPlayOpened" />
            <label class="ball menu-open-button text-center" for="menu-open">
                <div class="mt-3">
                    Play!
                    <div style="font-size: 9pt">{{ pointsPerPlay }} points</div>
                </div>
            </label>
            <div class="menu-item-2 menu-item ball" @click="checkOpenBox(1)">
                <div class="mt-3">
                    x1
                    <div style="font-size: 9pt">{{ pointsPerPlay }} pts</div>
                </div>
            </div>
            <div class="menu-item-3 menu-item ball" @click="checkOpenBox(10)">
                <div class="mt-3">
                    x10
                    <div style="font-size: 9pt">{{ pointsPerPlay * 10 }} pts</div>
                </div>
            </div>
            <div class="menu-item-4 menu-item ball" @click="checkOpenBox(25)">
                <div class="mt-3">
                    x25
                    <div style="font-size: 9pt">{{ pointsPerPlay * 25 }} pts</div>
                </div>
            </div>
            <!-- <div class="menu-item-4 menu-item ball" @click="checkOpenBox(50)">
                <div class="mt-3">
                    x50
                    <div style="font-size: 9pt">{{ pointsPerPlay * 50 }} pts</div>
                </div>
            </div>
            <div class="menu-item-5 menu-item ball" @click="checkOpenBox(100)">
                <div class="mt-3">
                    x100
                    <div style="font-size: 9pt">{{ pointsPerPlay * 100 }} pts</div>
                </div>
            </div> -->
        </nav>
        <b-modal id="m-confirm" hide-footer hide-header centered no-close-on-backdrop
            body-bg-variant="dark" body-text-variant="light"
        >
            <div class="text-center">
                Spend <span class="highlight">{{ $helper.formatThousand(pointsPerPlay * playFor) }}</span> point(s) to open <span class="highlight">{{ playFor }}</span> treasure chest(s)?
            </div>
            <div class="mt-4 d-flex flex-row justify-content-between">
                <b-button class="ml-4" :disabled="isSubmitting" @click="$bvModal.hide('m-confirm')">
                    Cancel
                </b-button>
                <b-button class="mr-4" variant="success" :disabled="isSubmitting" @click="confirmPlay">
                    {{ isSubmitting ? "Preparing..." : "Confirm" }}
                </b-button>
            </div>
        </b-modal>
        <b-modal id="m-insufficient-points" hide-footer hide-header centered
            body-bg-variant="dark" body-text-variant="light"
        >
            <div class="text-center">
                Insufficient points! You'll need <span class="highlight">{{ $helper.formatThousand(totalPointsToPlay) }}</span> point(s) to open <span class="highlight">{{ playFor }}</span> treasure chest(s)!
            </div>
            <div class="mt-4 text-right">
                <b-button @click="$bvModal.hide('m-insufficient-points')">
                    OK
                </b-button>
            </div>
        </b-modal>
        <b-modal id="m-error" title="Error" hide-footer hide-header centered
            body-bg-variant="dark" body-text-variant="light">
            Error: {{ errorMessage }}
            <div class="mt-4 text-right">
                <b-button variant="success" @click="$bvModal.hide('m-error')">
                    OK
                </b-button>
            </div>  
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'gacha-package-detail',
    data() {
        return {

            initStatus: 0,
            isPlayOpened: false,

            isFetching: false,
            isSubmitting: false,
            errorMessage: "",

            currentPoint: 0,
            rewards: [],
            
            name: "",
            image: "",
            daysLeft: 0,
            pointsPerPlay: 0,

            playFor: 1,
            playIndex: 0,
            playForSteps: [1, 10, 25, 50, 100],
            totalPointsToPlay: 0,

            soundOpen: new Audio("/audio/open.mp3"),
            soundClose: new Audio("/audio/close.mp3"),
            soundReady: new Audio("/audio/ready.mp3")

        }
    },
    watch: {

        isPlayOpened(newVal) {
            
            if(newVal == true) {
                this.soundOpen.play()
            }
            else {
                this.soundClose.play()
            }

        }

    },
    methods: {

        init() {

            this.initStatus = 0
            
            let id = this.$route.params.id

            let getDetail = this.$axios.post("api/v1/holychest/get-holy-chest-detail/" + id).then(res => {
                const data = res.data.data

                this.name = data.name
                this.image = data.image
                this.daysLeft = data.days_left
                this.pointsPerPlay = data.points_perplay
                this.totalPointsToPlay = this.playFor * this.points_perplay
                this.rewards = data.details

                this.rewards.forEach(it => {
                    it.name = it.reward.title;
                    it.image = it.reward.image_url

                    if(it.grade == 1) {
                        it.css = "gold"
                    } else if(it.grade == 2) {
                        it.css = "silver"
                    } else if(it.grade == 3) {
                        it.css = "bronze"
                    } else {
                        it.css = "zonk"
                    }
                })

                // Tambahan zonk
                this.rewards.push({
                    tier: 4,
                    name: "ZONK!!",
                    image: "/images/zonk.png",
                    css: 'zonk'
                })

            })

            Promise.all([getDetail]).then(res => {
                this.initStatus = 1
            })
            .catch(error => {
                this.initStatus = -1
            })

        },

        checkOpenBox(playFor) {

            this.playFor = playFor
            this.totalPointsToPlay = playFor * this.pointsPerPlay

            if(this.currentPoint < this.totalPointsToPlay) {
                return this.$bvModal.show("m-insufficient-points")
            }

            this.$bvModal.show("m-confirm")

        },

        confirmPlay() {

            this.isSubmitting = true

            let payload = {
                holy_chest_id: this.$route.params.id,
                play_for: this.playFor
            }

            this.$axios.post("api/v1/holychest/play-holy-chest", payload).then(res => {

                let data = res.data.data
                localStorage.setItem("point", data.remaining_point)
                this.currentPoint = data.remaining_point
                this.soundReady.play()

                setTimeout(() => {

                    this.$router.push({
                        name: "Gacha Opening",
                        params: {
                            result: data
                        }
                    })

                }, 2000)

            })
            .catch(error => {

                this.isSubmitting = false
                
                try {
                    let message = error.response.data.data.message
                    if (message == "Point is not enough for play") {

                        this.currentPoint = error.response.data.data.point
                        localStorage.setItem("point", this.currentPoint)

                        this.$bvModal.show("m-insufficient-points")

                    }   
                    else {
                        this.errorMessage = message
                        this.$bvModal.show("m-error")
                    }
                }
                catch(error) {
                    this.errorMessage = "Failed to connect to the server, please try again!"
                    this.$bvModal.show("m-error")
                }

            })

        },

        unfocusSelection() {
            this.isPlayOpened = false
        }

    },
    created() {
        this.currentPoint = localStorage.getItem("point")
        this.init()
    }
}
</script>

<style scoped>

    .highlight {
        color: yellow;
        font-weight: bold;
    }

    .banner-image {
        border-radius: 5px;
        width: 100%;
        margin-bottom: 8px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.75);
    }

    .reward-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        /* overflow-x: scroll; */
        /* -ms-overflow-style: none;
        scrollbar-width: none; */
    }

    .reward-container::-webkit-scrollbar {
        display: none;
    }

</style>
